import { TYPE, TAG, github, pages } from './constants'

export default [
  {
    name: [
      '',
      'Build',
      'Off',
    ],
    tags: [
      TAG.game,
      TAG.canvas,
    ],
    url: 'https://build-off.vercel.app/',
    github: 'https://github.com/buildpass-au/build-off',
    image: ['buildoff1', 'buildoff2', 'buildoff3', 'buildoff4', 'buildoff5', 'buildoff6'],
    markdown: 'buildoff',
    style: TYPE.bigbird,
  },
  {
    name: [
      'REA quiz',
      'ID',
    ],
    tags: [
      TAG.rea,
      TAG.game,
      TAG.hooks,
      TAG.graphql,
    ],
    url: 'https://pages.git.realestate.com.au/james-formica/quizID/',
    github: 'https://git.realestate.com.au/james-formica/quizID',
    image: ['quizID1', 'quizID2', 'quizID3', 'quizID4'],
    markdown: 'quizID',
    style: TYPE.bigbird,
  },
  {
    name: [
      'JuniorDev',
      ' Hacktoberfest',
    ],
    tags: [
      TAG.crazy,
    ],
    url: 'https://juniordevio.github.io/hacktoberfest-halloween-graffiti/',
    github: 'https://github.com/juniordevio/hacktoberfest-halloween-graffiti',
    image: 'hacktoberfest2019',
    markdown: 'hacktoberfest2019',
    style: TYPE.elmo,
  },
  {
    name: [
      'Bi',
      'cycles',
    ],
    tags: [
      TAG.art,
      TAG.rea,
    ],
    url: pages('bicycles'),
    github: github('bicycles'),
    image: 'bicycles',
    markdown: 'bicycles',
    style: TYPE.elmo,
  },
  {
    name: [
      'JD',
      '@',
      'DDD',
    ],
    tags: [
      TAG.crazy,
    ],
    url: pages('junior-dev-community-site-ddd-2019'),
    github: github('junior-dev-community-site-ddd-2019'),
    bgPosition: 'top center',
    image: 'ddd',
    markdown: 'ddd',
    style: TYPE.elmo,
  },
  {
    name: [
      'Word',
      'ly',
    ],
    tags: [
      TAG.react,
      TAG.hooks,
      TAG.art,
    ],
    url: pages('wordly'),
    github: github('wordly'),
    bgPosition: 'top center',
    image: 'wordly',
    markdown: 'wordly',
    style: TYPE.bigbird,
  },
  {
    name: [
      '',
      'Hook',
      'ed',
    ],
    tags: [
      TAG.react,
      TAG.hooks,
      TAG.game,
      TAG.crazy,
    ],
    url: 'https://hooked.fun/',
    github: github('hooked'),
    bgPosition: 'top center',
    image: 'hooked',
    markdown: 'hooked',
    style: TYPE.bigbird,
  },
  {
    name: [
      'CSS Grid',
      'City',
    ],
    tags: [
      TAG.css,
    ],
    url: pages('grid-city'),
    github: github('grid-city'),
    bgPosition: '50% 100%',
    image: 'gridcity',
    markdown: 'gridcity',
    style: TYPE.elmo,
  },
  {
    name: [
      'Spotify™️',
      'Turntable',
    ],
    tags: [
      TAG.react,
    ],
    url: pages('spotify-turntable'),
    github: github('spotify-turntable'),
    bgPosition: '50% 100%',
    image: 'turntable',
    markdown: 'turntable',
    style: TYPE.elmo,
  },
  {
    name: [
      "Thanos'",
      'Fate',
    ],
    tags: [
      TAG.react,
      TAG.canvas,
    ],
    url: pages('thanos-fate'),
    github: github('thanos-fate'),
    bgPosition: '50% 100%',
    image: [
      'thanoshome',
      'thanosalive',
      'thanosdead',
    ],
    markdown: 'thanos',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Fourier',
      'Circles',
    ],
    tags: [
      TAG.canvas,
      TAG.art,
    ],
    url: pages('fourier-circles'),
    github: github('fourier-circles'),
    bgPosition: '60% 50%',
    image: 'fourier',
    markdown: 'fourier',
    style: TYPE.elmo,
  },
  {
    name: [
      'O(n)',
      'Week+',
    ],
    tags: [
      TAG.react,
      TAG.canvas,
    ],
    url: pages('onweek-schedule'),
    bgPosition: 'center',
    image: [
      'onweekheader',
      'onweekschedule',
      'onweekcanvas',
      'onweekfooter',
    ],
    markdown: 'onweek',
    style: TYPE.bigbird,
  },
  {
    name: [
      'You',
      'Tube',
    ],
    tags: [
      TAG.tutorial,
    ],
    url: 'https://www.youtube.com/channel/UChUKUZTIK8eFDHSuX_5EBrA',
    bgPosition: 'top left',
    image: 'youtube',
    markdown: 'youtube',
    style: TYPE.elmo,
  },
  {
    name: [
      'Hub',
      'ly',
    ],
    tags: [
      TAG.react,
      TAG.rea,
    ],
    bgPosition: 'top center',
    image: [
      'hublyhome',
      'hublymap',
      'hublycarbon',
      'hublytime',
    ],
    markdown: 'hubly',
    style: TYPE.bigbird,
  },
  {
    name: [
      'One',
      'Team',
    ],
    tags: [
      TAG.react,
      TAG.rea,
      TAG.graphql,
    ],
    bgPosition: 'top center',
    image: [
      'oneteamdashboard',
      'oneteamnewsprint',
      'oneteamcalendar',
      'oneteamretro',
    ],
    markdown: 'oneteam',
    style: TYPE.bigbird,
  },
  {
    name: [
      'CSS',
      'Monster',
    ],
    tags: [
      TAG.css,
      TAG.art,
    ],
    url: pages('css-monster'),
    github: github('css-monster'),
    image: 'monster',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Dear',
      'Rent',
      'Diary',
    ],
    tags: [
      TAG.react,
      TAG.canvas,
      TAG.game,
      TAG.crazy,
      TAG.rea,
    ],
    url: pages('dear-rent-diary'),
    github: github('dear-rent-diary'),
    image: 'rent',
    markdown: 'rent',
    style: TYPE.elmo,
  },
  {
    name: [
      'Lindenmayer',
      'Aquarium',
    ],
    tags: [
      TAG.canvas,
      TAG.art,
    ],
    url: pages('Lindenmayer-Aquarium'),
    github: github('Lindenmayer-Aquarium'),
    image: 'aquarium',
    markdown: 'aquarium',
    style: TYPE.elmo,
  },
  {
    name: [
      'L-System',
      'Art',
    ],
    tags: [
      TAG.canvas,
      TAG.art,
    ],
    url: pages('L-System-Fun'),
    github: github('L-System-Fun'),
    image: 'lsystem',
    markdown: 'lsystem',
    style: TYPE.bigbird,
  },
  {
    name: [
      'CSS',
      'Car',
    ],
    tags: [
      TAG.css,
      TAG.art,
    ],
    url: pages('css-car'),
    github: github('css-car'),
    image: 'csscar',
    markdown: 'csscar',
    style: TYPE.elmo,
  },
  {
    name: [
      'CSS',
      'House',
    ],
    tags: [
      TAG.css,
      TAG.art,
    ],
    url: pages('css-house'),
    github: github('css-house'),
    image: 'csshouse',
    markdown: 'csshouse',
    style: TYPE.elmo,
  },
  {
    name: [
      'CSS',
      'Traffic',
      'Light',
    ],
    tags: [
      TAG.css,
      TAG.art,
    ],
    url: pages('css-trafficlight'),
    github: github('css-trafficlight'),
    image: 'csstrafficlight',
    markdown: 'csstrafficlight',
    style: TYPE.elmo,
  },
  {
    name: [
      'CSS',
      'Goldfish',
    ],
    tags: [
      TAG.css,
      TAG.art,
    ],
    url: pages('css-goldfish'),
    github: github('css-goldfish'),
    image: 'cssgoldfish',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Canvas',
      'Madness',
    ],
    tags: [
      TAG.presentation,
    ],
    url: pages('canvas-madness'),
    github: github('canvas-madness'),
    image: 'canvasmadness',
    markdown: 'canvasmadness',
    style: TYPE.elmo,
  },
  {
    name: [
      'Llama',
      'rama',
    ],
    tags: [
      TAG.react,
      TAG.canvas,
      TAG.game,
    ],
    url: pages('llamarama'),
    github: github('llamarama'),
    image: 'llamarama',
    markdown: 'llamarama',
    bgPosition: 'left center',
    style: TYPE.elmo,
  },
  {
    name: [
      'Line',
      'Art',
    ],
    tags: [
      TAG.canvas,
      TAG.art,
    ],
    url: pages('lineart'),
    github: github('lineart'),
    image: 'lineart',
    markdown: 'lineart',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Sketch',
      'ly',
    ],
    tags: [
      TAG.react,
      TAG.canvas,
      TAG.game,
    ],
    url: 'https://sketchly.netlify.com/',
    image: 'sketchly',
    markdown: 'sketchly',
    bgPosition: 'left center',
    style: TYPE.elmo,
  },
  {
    name: [
      'Mood',
      'ly',
    ],
    tags: [
      TAG.canvas,
      TAG.art,
      TAG.crazy,
    ],
    url: pages('moodly'),
    github: github('moodly'),
    image: 'moodly',
    markdown: 'moodly',
    style: TYPE.elmo,
  },
  {
    name: [
      'CSS',
      'Grid',
    ],
    tags: [
      TAG.presentation,
      TAG.css,
    ],
    url: pages('css-grids'),
    github: github('css-grids'),
    image: 'cssgrid',
    markdown: 'cssgrid',
    style: TYPE.elmo,
  },
  {
    name: [
      'A.',
      'C',
      '.E',
    ],
    tags: [
      TAG.crazy,
    ],
    url: pages('ace'),
    github: github('ace'),
    image: 'ace',
    markdown: 'ace',
    bgPosition: 'right center',
    style: TYPE.elmo,
  },
  {
    name: [
      'My',
      'Blog',
      ' (inactive)',
    ],
    tags: [
      TAG.blog,
    ],
    url: pages('blog'),
    github: github('blog'),
    image: 'blog',
    bgPosition: 'left center',
    markdown: 'blog',
    style: TYPE.elmo,
  },
  {
    name: [
      'Split',
      'tr',
    ],
    tags: [
      TAG.canvas,
      TAG.game,
      TAG.sass,
    ],
    url: pages('splittr'),
    github: github('splittr'),
    image: 'splittr',
    markdown: 'splittr',
    style: TYPE.elmo,
  },
  {
    name: [
      'Simple',
      'Paint',
    ],
    tags: [
      TAG.canvas,
    ],
    url: pages('simplepaint'),
    github: github('simplepaint'),
    image: 'simplepaint',
    markdown: 'simplepaint',
    style: TYPE.elmo,
  },
  {
    name: [
      'Last',
      'Hand',
      'Standing',
    ],
    tags: [
      TAG.game,
      TAG.ruby,
    ],
    url: 'https://lasthandstanding.herokuapp.com',
    github: 'https://github.com/julianrizzo/last-hand-standing-app',
    image: 'lasthandstanding',
    markdown: 'lasthandstanding',
    style: TYPE.elmo,
  },
  {
    name: [
      'CSS',
      'Playground',
    ],
    tags: [
      TAG.presentation,
      TAG.css,
    ],
    url: 'https://cssplayground.herokuapp.com',
    github: github('fun-with-css'),
    image: 'cssplayground',
    markdown: 'cssplayground',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Event',
      'Timeline',
    ],
    tags: [],
    url: '',
    github: github('lot-2318-timeline'),
    image: 'eventtimeline',
    markdown: 'eventtimeline',
    style: TYPE.elmo,
  },
  {
    name: [
      'Eat',
      'Week',
    ],
    tags: [],
    url: '',
    github: github('EatWeek'),
    image: 'eatweek',
    markdown: 'eatweek',
    style: TYPE.elmo,
  },
  {
    name: [
      'T',
      'rains',
    ],
    tags: [
      TAG.canvas,
      TAG.game,
    ],
    url: pages('trains'),
    github: github('Trains'),
    image: 'trains',
    bgPosition: 'center',
    markdown: 'trains',
    style: TYPE.bigbird,
  },
  {
    name: [
      'Off',
      'The',
      'Record',
    ],
    tags: [
      TAG.crazy,
      TAG.ruby,
    ],
    url: 'https://offtherecord2.herokuapp.com',
    github: github('off-the-record'),
    image: 'offtherecord',
    markdown: 'offtherecord',
    bgPosition: 'left center',
    style: TYPE.elmo,
  },
]
